<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col><el-breadcrumb><el-breadcrumb-item class="pointer" :to="{ path: '/paramSetting' }">导航</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col>
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <el-col :span="24">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip class="item" effect="dark" content="保存基本信息和扩展属性" placement="bottom">
                          <el-button type="primary" size="small" @click="submitForm('electronicFence')">保存</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="返回列表" placement="bottom">
                          <el-button @click="goIndex()" type="primary" size="small">返回</el-button>
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-col :span="10">
                  <el-form :model="electronicFence" ref="electronicFence" label-width="150px" class="electronicFence"
                    size="mini">
                    <!-- <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="electronicFence.id"></el-input>
                      </el-form-item>
                    </el-col> -->
                    <el-row>
                      <el-col :sm="24" :md="24" :lg="16">
                        <el-form-item label="检索" prop="检索">
                          <el-input id="tipinput" v-model="addressPosition"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :sm="24" :md="24" :lg="24">
                        <el-form-item label="所在地区" prop="area">
                          <v-distpicker v-model="text" :province="prov" :city="cty" :area="dit"
                            @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea"></v-distpicker>
                        </el-form-item>
                      </el-col>
                      <el-col :sm="24" :md="24" :lg="16">
                        <el-form-item label="地址" prop="地址">
                          <el-input v-model="electronicFence.address"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :sm="24" :md="24" :lg="12">
                        <el-form-item label="电子围栏名称" prop="electronicFenceName">
                          <el-input v-model="electronicFence.electronicFenceName" style="width: 185px"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- <el-row>
                      <el-col :sm="24" :md="24" :lg="12">
                        <el-form-item label="锁编号" prop="deviceCode">
                          <el-input
                            v-model="electronicFence.deviceCode"
                            style="width: 185px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row> -->
                    <el-row>
                      <el-col :sm="24" :md="24" :lg="12">
                        <el-form-item label="所属运营商" prop="operatorName">
                          <el-select v-model="operatorObj" placeholder="请选择运营商" @change="selectOperator" clearable
                            value-key="operatorName">
                            <el-option v-for="item in operatorList" :key="item.operatorCode" :label="item.operatorName"
                              :value="item">
                              <!-- v-show="item.type == it.value||!selectIdsArr.includes(it.value)" -->
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col> </el-row><el-row>
                      <el-col :sm="24" :md="24" :lg="12">
                        <el-form-item label="所属管理员" prop="adminName">
                          <el-select v-model="adminObj" placeholder="请选择管理员" @change="selectCheck" clearable
                            value-key="adminName">
                            <el-option v-for="item in adminList" :key="item.adminCode" :label="item.adminName"
                              :value="item">
                              <!-- v-show="item.type == it.value||!selectIdsArr.includes(it.value)" -->
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :sm="24" :md="24" :lg="12">
                        <el-form-item label="电子围栏状态" prop="fenceStatus">
                          <el-select v-model="electronicFence.fenceStatus">
                            <el-option label="未使用" :value="0"></el-option>
                            <el-option label="已使用" :value="1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :sm="24" :md="24" :lg="12">
                        <el-form-item label="电子围栏建设状态" prop="fenceStatus">
                          <el-select
                            :class="electronicFence.fenceBuildingStatus == 0 ? 'jianshe' : electronicFence.fenceBuildingStatus == 1 ? 'yunxing' : electronicFence.fenceBuildingStatus == 2 ? 'weixiu' : ''"
                            v-model="electronicFence.fenceBuildingStatus">
                            <el-option style="color:skyblue;" label="建设中" value="0" key="0"></el-option>
                            <el-option style="color:green;" label="运行" value="1" key="1"></el-option>
                            <el-option style="color:red;" label="维修" value="2" key="2"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :sm="24" :md="24" :lg="12">
                        <el-form-item label="电子围栏描述" prop="electronicFenceName">
                          <el-input type="textarea" :rows="3" v-model="electronicFence.description"
                            style="width: 185px"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-col>
                <el-col :span="14">
                  <div id="container" style="box-shadow: 0 2px 10px 0 rgba(14,33,39,.2);"></div>
                  <div class="input-card">
                    <h4>电子围栏绘制</h4>
                    <div class="input-item">
                      <input type="button" class="btn" value="开始绘制" id="start" @click="startDrawPolygon()" />
                      <input type="button" class="btn" value="清空" id="pause" @click="clearPolygon()" />
                    </div>
                    <div class="input-item">
                      <input type="button" class="btn" value="结束并保存" id="resume" @click="stopAndSave()" />
                      <input type="button" class="btn" value="重新绘制" id="stop" @click="redrawPolygon()" />
                    </div>
                  </div>
                </el-col>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
export default {
  components: { VDistpicker },
  data() {
    return {
      //地图部分
      map: null,
      mouseTool: null,
      locList: [],
      placeList: "",

      polyType: {
        strokeColor: "#FF33FF",
        strokeOpacity: 1,
        strokeWeight: 4,
        strokeOpacity: 0.2,
        fillColor: "#1791fc",
        fillOpacity: 0.4,
        // 线样式还支持 'dashed'
        strokeStyle: "solid"
        // strokeStyle是dashed时有效
        // strokeDasharray: [30,10],
      },
      polygonList: [],
      savedPolygon: [],
      //表单部分
      adminObj: "",
      operatorObj: "",
      srcList: [],
      // 当前页数
      currPage: 1,
      pageSize: 10,
      page: 1,
      areas: "",
      limit: 10,
      seeThis: 0,
      loading: true,
      prov: "",
      cty: "",
      dit: "",
      id: "",
      sum: "",
      arr: [], // 电子围栏坐标点
      arr1: [],
      text: "",
      txt1: "",
      txt2: "",
      txt3: "",
      adminList: "",
      operatorList: [],
      electronicFence: {
        id: "",
        area: "",
        operatorName: "",
        administratorCode: "",
        electronicFenceName: "",
        administratorName: "",
        operatorCode: "",
        fenceStatus: "",
        mapPoint: "",
        deviceCode: "",
        fenceBuildingStatus: "",
        address: '',
      },
      fenceBuildingStatusOption: [
        {
          name: "建设中",
          value: "0"
        },
        {
          name: "运行",
          value: "1"
        },
        {
          name: "维修",
          value: "2"
        }
      ],
      addressPosition: "",
      latitude: "", // 点击详情纬度
      longitude: "", // 点击详情经度
      markers: [],
      marker: null,
    };
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo(); // 获取点击详情数据
    this.loadOperator();
    this.loadAdmin();
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap(114.459779, 38.087936);
    });
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
  methods: {
    getLocation() {
      let that = this
      that.map.plugin('AMap.Geolocation', function () {
        let geolocation = new AMap.Geolocation({
          timeout: 3000, //若在指定时间内未定位成功，返回超时错误信息“TIMEOUT”
          GeoLocationFirst: true, //默认为false，设置为true的时候可以调整PC端为优先使用浏览器定位，失败后使用IP定位
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          enableHighAccuracy: false,
        });
        that.map.addControl(geolocation)
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', function (res) {
          console.log('res', res);
        })
        AMap.event.addListener(geolocation, 'error', function (data) {
          console.log('data', data);
        });
      })
    },
    initMap(longitude, latitude) {
      var that = this
      that.map = new AMap.Map("container", {
        resizeEnable: true,
        center: [longitude, latitude],
        // center: [114.459779, 38.087936],
        zoom: 16
      });

      that.getLocation()
      // that.map.on('moveend',function(){
      //    let center = that.map.getCenter()
      //    console.log('center',center);
      //    that.marker.setPosition(center)
      // })
      //输入提示
      var autoOptions = {
        input: "tipinput"
      };
      var auto = new AMap.Autocomplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
        map: that.map
      });
      //构造地点查询类
      AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
      function select(e) {
        placeSearch.setCity(e.poi.adcode);
        placeSearch.search(e.poi.name); //关键字查询查询
      }
      //创建maker
      that.marker = new AMap.Marker({
        draggable: true,
      });
      that.marker.setMap(that.map);
      that.marker.on('dragend', function () {//拖动坐标获取新坐标 marker.on('dragend', function() {//拖动坐标获取新坐标
        console.log('最新坐标：', that.marker.getPosition())
        var coordinate = that.marker.getPosition()
        that.electronicFence.longitude = coordinate.lng
        that.electronicFence.latitude = coordinate.lat
      })
    },
    startDrawPolygon() {
      this.clearPolygon();
      let __this = this;
      this.map.plugin("AMap.MouseTool", function () {
        __this.mouseTool = new AMap.MouseTool(__this.map);
      });
      if (__this.electronicFence.fenceBuildingStatus == 0) {
        __this.polyType.fillColor = "#1791fc";
      } else if (__this.electronicFence.fenceBuildingStatus == 1) {
        __this.polyType.fillColor = "#8ec14a";
      } else {
        __this.polyType.fillColor = "#f66600";
      }
      this.mouseTool.polygon(this.polyType);
      //监听draw事件可获取画好的覆盖物
      this.polygonList = [];
      this.mouseTool.on("draw", function (e) {
        __this.polygonList.push(e.obj);
        console.log("绘制的多边形", __this.polygonList);
        __this.mouseTool.close();
      });
    },
    stopAndSave() {
      // this.mouseTool.close();
      let savedPolygon = [];
      for (let i = 0; i < this.polygonList.length; i++) {
        console.log(this.polygonList[i].getOptions());
        let op = this.polygonList[i].getOptions();
        let routePath = [];
        op.path.forEach(item => {
          routePath.push([item.lng, item.lat]);
        });
        let savearr = {
          path: routePath,
          strokeColor: op.strokeColor,
          strokeOpacity: op.strokeOpacity,
          fillOpacity: op.fillOpacity,
          fillColor: op.fillColor,
          zIndex: op.zIndex
        };
        this.savedPolygon.push(savearr);
      }
      let save = JSON.stringify(this.savedPolygon);
      let locList = this.savedPolygon[0].path;
      this.sum = locList.reduce((pre, cur) => {
        return pre + ";" + cur;
      });
      // console.log(this.sum);
      this.$http
        .post("/electronicFence/update2", {
          id: this.id,
          mapPoint: this.sum
        })
        .then(res => {
          console.log(res);
        });
      // console.log(res)
      // for(let item in save){
      //   console.log(item)
      // }
      // 模拟数据库存取
      sessionStorage.setItem("savedPolygon", save);
    },
    clearPolygon() {
      // this.map.remove(this.polygonList);
      this.polygonList = [];
      this.map.clearMap(); // 清除地图上的所有覆盖物
    },
    // 用保存的数据重新绘制多边形并添加事件
    redrawPolygon() {
      let __this = this;
      // 用浏览器模拟数据库存取
      let savedPolygon = JSON.parse(sessionStorage.getItem("savedPolygon"));
      // console.log("重绘多边形数据", savedPolygon);
      if (savedPolygon == null) return;
      for (let i = 0; i < savedPolygon.length; i++) {
        var polygon = new AMap.Polygon(savedPolygon[i]);
        this.map.add(polygon);
      }
    },
    goIndex() {
      this.$router.go(-1);
    },
    onChangeProvince(a) {
      // console.log(a);
      this.txt1 = a.value + ",";
      // console.log(this.txt1);
    },
    onChangeCity(a) {
      // console.log(a);
      this.txt2 = a.value + ",";
    },
    onChangeArea(a) {
      // console.log(a);
      this.txt3 = a.value;
      this.text = this.txt1 + this.txt2 + this.txt3;

      // console.log(this.text)
    },
    //提交功能
    submitForm(electronicFence) {
      var that = this;
      that.electronicFence.area = that.text;
      // console.log(that.adminObj);
      if (that.electronicFence.area == "") {
        that.$message("所属地区不为空");
        return false;
      } else if (that.electronicFence.administratorName == "") {
        that.$message("所属管理员不为空");
        return false;
      } else if (that.electronicFence.electronicFenceName == "") {
        that.$message("电子围栏名称不为空");
        return false;
      } else if (that.electronicFence.operatorName == "") {
        that.$message("所属运营商不为空");
        return false;
      } else if (that.electronicFence.fenceStatus.value == "") {
        that.$message("设备状态不为空");
        return false;
      }
      // console.log(that.sum);
      that.electronicFence.mapPoint = that.sum;
      // console.log(that.electronicFence.mapPoint);
      //   alert()
      that.$http
        .post("/electronicFence/save", {
          id: that.id,
          fenceBuildingStatus: that.electronicFence.fenceBuildingStatus,
          area: that.electronicFence.area,
          deviceCode: that.electronicFence.deviceCode,
          operatorName: that.electronicFence.operatorName,
          administratorCode: that.electronicFence.administratorCode,
          electronicFenceName: that.electronicFence.electronicFenceName,
          administratorName: that.electronicFence.administratorName,
          operatorCode: that.electronicFence.operatorCode,
          fenceStatus: that.electronicFence.fenceStatus,
          mapPoint: that.electronicFence.mapPoint,
          description: that.electronicFence.description,
          adminPhone: that.electronicFence.adminPhone,
          address: that.electronicFence.address,
          longitude: that.electronicFence.longitude,
          latitude: that.electronicFence.latitude,
        })
        .then(function (response) {
          if (response.data.code === 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true
            });
            that.$router.go(-1);
          } else {
            that.$notify.warning({
              title: "提示",
              message: response.data.message,
              showClose: true
            });
          }
        })
        .catch(function (error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true
          });
        });
    },
    selectCheck(item) {
      this.electronicFence.administratorCode = item.adminCode;
      this.electronicFence.administratorName = item.adminName;
      this.electronicFence.adminPhone = item.adminPhone
    },
    selectOperator(item) {
      this.electronicFence.operatorCode = item.operatorCode;
      this.electronicFence.operatorName = item.operatorName;
    },
    loadOperator() {
      var that = this;
      that.$http.post("/userOperator/queryAll", {}).then(function (response) {
        if (response.data.code == 200) {
          that.operatorList = response.data.data;
        }
      });
    },
    loadAdmin() {
      var that = this;
      that.$http.post("/userAdmin/queryList", {}).then(function (response) {
        if (response.data.code == 200) {
          that.adminList = response.data.data;
        }
      });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/electronicFence/list", {
            page: 1,
            limit: 10,
            condition: {
              id: that.id
            }
          })
          .then(function (response) {
            // console.log("---------");
            if (response.data.code == 200) {
              // console.log(response.data.data.data[0]);
              that.total = response.data.data.count;
              that.electronicFence = response.data.data.data[0];
              that.prov = that.electronicFence.area.split(",")[0];
              that.cty = that.electronicFence.area.split(",")[1];
              that.dit = that.electronicFence.area.split(",")[2];
              that.electronicFence.fenceStatus = parseInt(
                that.electronicFence.fenceStatus
              );
              that.adminObj = that.electronicFence.administratorName;
              that.operatorObj = that.electronicFence.operatorName;
              that.arr = that.electronicFence.mapPoint.split(";");

              that.sum = that.electronicFence.mapPoint;
              that.latitude = that.electronicFence.latitude;
              that.longitude = that.electronicFence.longitude;
              that.initMap(that.longitude, that.latitude); // 渲染中心坐标点
              // that.setMarker([that.longitude, that.latitude]); // 添加标记
              // that.arr1=that.electronicFence.mapPoint.split(";")[0]
              for (let i = 0; i < that.arr.length; i++) {
                // console.log(that.arr[i].split(","));
                that.arr1.push(that.arr[i].split(","));
              }

              // console.log(that.arr1);
              // console.log(that.electronicFence.mapPoint.split(";")[0]);
              // that.adminObj.administratorCode=that.electronicFence.administratorCode
            }
            var polygon = new AMap.Polygon({
              fillColor: "#1791fc",
              fillOpacity: 0.4,
              path: that.arr1,
              strokeColor: "#FF33FF",
              strokeOpacity: 0.2,
              zIndex: 10
            });
            if (that.electronicFence.fenceBuildingStatus == 0) {
              polygon.fillColor = "#1791fc";
            } else if (that.electronicFence.fenceBuildingStatus == 1) {
              polygon.fillColor = "#8ec14a";
            } else {
              polygon.fillColor = "#f66600";
            }
            that.map.add(polygon);
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      // var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      //   var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      // var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      //   if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
      //     this.seeThis = 0;
      //   }
      //   if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
      //     this.seeThis = 1;
      //   }
      // if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
      //   this.seeThis = 3;
      // }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
    //  添加标记
    setMarker(lnglat) {
      let that = this
      let marker = new AMap.Marker({
        position: lnglat,
        draggable: true,

      });
      marker.setMap(that.map);
      marker.on('dragend', function () {//拖动坐标获取新坐标 marker.on('dragend', function() {//拖动坐标获取新坐标
        console.log('最新坐标：', marker.getPosition())
        var coordinate = marker.getPosition()
        that.electronicFence.longitude = coordinate.lng
        that.electronicFence.latitude = coordinate.lat
      })
      // this.removeMarker();
      // this.markers.push(marker);
    },
    removeMarker() {
      // if (this.markers) {
      //   this.map.remove(this.markers);
      // }
    }
  }
};
</script>

<style lang="less" scope>
@import url("https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css");

#container {
  width: 100%;
  height: 500px;
}

.input-card .btn {
  margin-right: 1.2rem;
  width: 9rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}

.h50 {
  height: 50px;
}

.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }

  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;

      li {
        height: 50px;
        line-height: 50px;

        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }

      li.active,
      li:hover {
        background-color: #fff;
      }
    }

    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;

      .p1 {
        margin-bottom: 20px;
      }

      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;

          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }

          .el-input {
            width: 247px;
          }

          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }

      .header-input {
        font-size: 13px;
      }

      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }

        .el-table {
          margin-top: 10px;
        }
      }
    }
  }

  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }

  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }

  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}

.jianshe input {
  color: skyblue;
}

.yunxing input {
  color: green;
}

.weixiu input {
  color: red;
}
</style>
